import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
// local
import {CustomTooltip} from "components/CustomTooltip";
import MiniSelect from "components/selects/MiniSelect";
import ActionSelect from "components/selects/ActionSelect";
import ProcurementStatusesSelect from "components/selects/ProcurementStatusesSelect";
import CustomRadioSwitch from "components/CustomRadioSwitch";
import {BulkRfpOperationModal} from "components/modals/BulkRfpOperationModal";
import CustomCheckbox from "components/CustomCheckbox";
import MultipleSelect from "components/selects/MultipleSelect";
import {LockValue} from "components/LockValue";
import {hasPermission} from "helpers/helper";
import {
  IconUsers,
  IconInbox,
  IconPulse,
  IconEye,
  IconPen,
  IconBubblesMessage,
  IconCalendarEmpty,
  IconSatellite,
  IconWrench,
} from "components/icons/index";
import {sortProcurementsSelectItems} from "constants/buyer.consts";
import {bulkOperationsProcurementsSelectItems} from "constants/select.consts";
import {bulkProcurementOperations} from "constants/buyer.consts";
import {color} from "constants/color.consts";
import cn from "classnames";

const ProcurementsHead = ({
  activeTab,
  // expandDisabled,
  filterParams,
  statuses,
  datesSteps,
  buyerList,
  currentUser,
  // collapseDisabled,
  counters,
  visibleCheckboxes,
  // clickOnExpand,
  // clickOnCollapse,
  clickOnSortFilter,
  clickOnSelect,
  clickOnBulkProcurementsUpdate,
  changeVisibleCheckboxes,
}) => {
  const {t} = useTranslation();
  const [procurementStatuses, setProcurementStatuses] = useState(
    filterParams?.status ?? statuses
  );
  const [userIds, setUserIds] = useState(
    filterParams.userId
      ? buyerList.filter((member) => filterParams.userId.includes(member.value))
      : []
  );
  const [bulkOperationModal, setBulkOperationModal] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => setIsScrolled(window.scrollY > 220);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const DatesStep = ({icon, tooltip, titleColor}) => (
    <div className="project-view-procurements-table_head__dates__step">
      <CustomTooltip
        text={tooltip}
        color={titleColor === "white" ? undefined : color[titleColor]}
        placement={isScrolled ? "bottom" : undefined}
      >
        <div
          className={`project-view-procurements-table_head__dates__step__head-icon ${titleColor}`}
        >
          {icon}
        </div>
      </CustomTooltip>
    </div>
  );

  const RecipientsTooltip = ({text, icon, element}) => (
    <div className={`project-view-procurements-table_head__details__${element}`}>
      <CustomTooltip text={text} placement={isScrolled ? "bottom" : "top"}>
        <span>{icon}</span>
      </CustomTooltip>
    </div>
  );

  const clickOnAction = (action) =>
    setBulkOperationModal(bulkProcurementOperations[action]);

  return (
    <div
      className={`project-view-procurements-table_head ${isScrolled ? "scrolled" : ""}`}
    >
      <div className="project-view-procurements-table_head__name">
        {/* We hid this previously cause it was not used
        {activeTab === 0 && (
          <ButtonTransparent
            name={"PROCUREMENT_EXPAND_ALL"}
            tiny
            rounded
            uppercase
            color="black"
            addClass="ml-4"
            disabled={expandDisabled}
            onClick={clickOnExpand}
          />
        )}
           */}
        <LockValue
          canPermissions={["ENABLE_PROCUREMENTS_BULK_ACTIONS"]}
          needLabel
          justHide
        >
          <CustomCheckbox
            checked={!!counters[0] && counters[0] === counters[1]}
            indeterminate={!!counters[0] && counters[0] !== counters[1]}
            initialButton={visibleCheckboxes}
            initialButtonIcon={<IconWrench color={color.midGray} />}
            initialButtonTooltip="PROJECT_BULK_MULTISELECT_TOOLTIP"
            onClickInitialButton={() =>
              hasPermission(["ENABLE_PROCUREMENTS_BULK_ACTIONS"]) &&
              changeVisibleCheckboxes()
            }
            onChange={clickOnSelect}
            addClass={"mr-4"}
          />
        </LockValue>
        {!!counters[0] && !!counters[1] && (
          <ActionSelect
            list={bulkOperationsProcurementsSelectItems}
            iconsList={[<IconSatellite />, <IconCalendarEmpty />]}
            isRelativeCustomContent
            customContent={
              bulkOperationModal && (
                <BulkRfpOperationModal
                  action={bulkOperationModal}
                  counters={counters}
                  currentUser={currentUser}
                  onSubmit={clickOnBulkProcurementsUpdate}
                  onClose={() => setBulkOperationModal(null)}
                />
              )
            }
            label="PROCUREMENT_BULK_OPERATION_EDIT_LABEL"
            onChange={clickOnAction}
            addClass="bulk-actions ml-2 mr-4"
          />
        )}
        {/* Hiding the collapse all button on the assumption it's not frequently used and eating too much horisontal space
            We may want it back later if users freak out.
        {activeTab === 0 && !counters[0] && (
          <ButtonTransparent
            name={"PROCUREMENT_COLLAPSE_ALL"}
            tiny
            rounded
            uppercase
            color="black"
            addClass="mr-4"
            disabled={collapseDisabled}
            onClick={clickOnCollapse}
          />
        )}
        */}
        <MiniSelect
          list={sortProcurementsSelectItems}
          defaultValue={filterParams.sort}
          prefixLabel
          itemAsObject
          onChange={(value) => clickOnSortFilter("sort", value)}
          label={t("PROCUREMENT_SORT_LABEL")}
        />
        <MultipleSelect
          title="PROCUREMENT_SELECT_BUYER_LABEL"
          titleInButton
          value={userIds}
          list={buyerList}
          tiny
          uppercaseButton
          initialsSelectionMode
          currentUserId={currentUser?.id}
          color="black"
          realLabels
          onChange={(value) => {
            setUserIds(value);
            clickOnSortFilter(
              "userId",
              value.map((item) => item.value)
            );
          }}
          addClass="ml-4 mr-4"
        />
        <CustomRadioSwitch
          checked={filterParams.noRft}
          uppercase
          label={t("PROCUREMENT_NO_RFT_LABEL")}
          small
          prefixLabelDistance={"5px"}
          isActive={(checked) => clickOnSortFilter("noRft", checked)}
        />
      </div>
      {[0, 1, 2].includes(activeTab) && ( // @alekslykov, I turned on the status filter for the schedule tab. Is there som reason not to?
        <div className="project-view-procurements-table_head__status">
          <ProcurementStatusesSelect
            list={statuses}
            multiple
            onChange={(value) => {
              setProcurementStatuses(value);
              clickOnSortFilter(
                "status",
                value.length === statuses.length ? null : value
              );
            }}
            defaultValue={filterParams?.status ?? statuses}
            selectedValue={procurementStatuses}
          />
        </div>
      )}
      {activeTab === 0 && (
        <div
          className={cn("project-view-procurements-table_head__details", {
            "disable-response": !hasPermission(["EDIT_PROCUREMENT_SUPPLIER_RESPONSE"]),
          })}
        >
          <RecipientsTooltip text="READ" icon={<IconEye />} element="icon" />
          <RecipientsTooltip text="INTERESTED" icon={<IconPulse />} element="icon" />
          <RecipientsTooltip text="CONTACT_PERSON" icon={<IconUsers />} element="icon" />
          <RecipientsTooltip text="HAS_OFFERED" icon={<IconInbox />} element="icon" />
          <RecipientsTooltip text="HAS_CONTRACTED" icon={<IconPen />} element="icon" />
          <RecipientsTooltip
            text="MESSAGES"
            icon={<IconBubblesMessage />}
            element="messages"
          />
        </div>
      )}
      {activeTab === 2 && (
        <div className="project-view-procurements-table_head__dates">
          {datesSteps.map((step, index) => {
            const {key, ...restProps} = step;
            return <DatesStep key={`${key}_${index}`} {...restProps} />;
          })}
        </div>
      )}
      {activeTab === 1 && (
        <React.Fragment>
          <div className="project-view-procurements-table_head__pre-cost"></div>
          <div className="project-view-procurements-table_head__cost">{t("BUDGET")}</div>
          <div className="project-view-procurements-table_head__cost">{t("SPENT")}</div>
          <div className="project-view-procurements-table_head__cost">{t("RESULT")}</div>
        </React.Fragment>
      )}
    </div>
  );
};

export default ProcurementsHead;
