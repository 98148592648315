import React, {useEffect, useState, useMemo} from "react";
import {inject, observer} from "mobx-react";
import {useNavigate, useParams, useLocation} from "react-router-dom";
//local
import {Layout} from "components/UI/layout";
import RequestHeadView from "./RequestView/RequestHeadView";
import RequestHeadEdit from "./RequestEdit/RequestHeadEdit";
import {Preloader} from "components/Preloader";
import {Tabs} from "components/Tabs";
import {REQUESTS, PROJECTS, toProjectId} from "constants/routes.const";
import {IconLock, IconHourglass} from "components/icons";
import {defaultNameFieldSearch} from "constants/user.consts";
import {color} from "constants/color.consts";
import {rfpStatuses} from "constants/buyer.consts";
import cn from "classnames";

const RequestPage = inject("store")(
  observer(({store: {buyer, organization: organizationStore}}) => {
    const {
      isNew,
      isEdit,
      isContract,
      isFinishedContract,
      currentRequest,
      currentProject,
      requestTabs,
      activeRequestTab,
      offerIdWithContract,
      rfpRecipientsFilterParams,
    } = buyer;
    const navigate = useNavigate();
    const location = useLocation();
    const {requestId, contractId, offerId} = useParams();
    const [loading, setLoading] = useState(true);
    const isEditMode = useMemo(() => isNew || isEdit, [isNew, isEdit]);
    const isDraft = !currentRequest?.id || currentRequest?.status === rfpStatuses.DRAFT;
    const unreadMessagesCount = currentRequest?.recipients?.registered?.reduce(
      (count, item) => count + item.unreadMessageCount,
      0
    );
    const totalMessagesCount = currentRequest?.recipients?.registered?.reduce(
      (count, item) => count + item.totalMessageCount,
      0
    );
    const totalOffersCount =
      (+currentRequest?.offersAmount ?? 0) + (+currentRequest?.manualOffersAmount ?? 0);

    const tabLine = <div className="request__tabs__tab-line">|</div>;

    useEffect(() => {
      organizationStore.getOrganizationMembers(
        "SHOW_RFT_CONTRACT_SIGNATORIES_DROPDOWN_USER"
      );
    }, [organizationStore]);

    useEffect(() => {
      !isEdit &&
        !isNew &&
        Object.keys(rfpRecipientsFilterParams).length > 2 &&
        buyer.setRfpRecipientsFilterParams(defaultNameFieldSearch);
    }, [buyer, isEdit, isNew, rfpRecipientsFilterParams]);

    useEffect(() => {
      buyer
        .getCurrentRequestInfo(requestId ?? contractId ?? location?.state?.requestId)
        .then(async (data) => {
          buyer.setCurrentProcurementId(data?.id);
          if (location?.state?.contractId || offerIdWithContract) {
            const offerId =
              location?.state?.from === "offers"
                ? location?.state?.offerId
                : location?.state?.contractId || offerIdWithContract;
            buyer
              .getOffer(offerId, true)
              .then(async () => {
                location?.state?.from === "offers"
                  ? buyer.setActiveRequestTab(2)
                  : buyer.setActiveRequestTab(3);
              })
              .finally(() => setLoading(false));
          } else {
            // set editMode when no PUBLISHED
            buyer.setEditStatus(data?.status === rfpStatuses.DRAFT);
            setLoading(false);
            (location?.state?.offerId || !!offerId) && buyer.setActiveRequestTab(2);
          }
        })
        .catch(() =>
          navigate(currentProject?.id ? toProjectId(currentProject.id) : PROJECTS)
        );
      return () => {
        buyer.getOffer();
        buyer.setActiveRequestTab(0);
        buyer.getContract();
        buyer.setInitialContract(false);
        buyer.setEditStatus(false);
        buyer.setNewRFPStatus(false);
        buyer.setViewSpecificOfferStatus(false);
        buyer.setRfpRecipientsFilterParams(defaultNameFieldSearch);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      requestId,
      contractId,
      buyer,
      offerIdWithContract,
      location?.state?.contractId,
      location?.state?.requestId,
      navigate,
      currentProject,
    ]);

    // common logic to tab setting
    useEffect(() => {
      if (location?.state?.contractId || offerIdWithContract) {
        buyer.setActiveRequestTab(
          activeRequestTab === 3 && activeRequestTab !== 4 ? 3 : activeRequestTab
        );
      } else {
        // set Messages tab to active if you clicked on one of the top notifications
        buyer.setActiveRequestTab(
          location?.state?.messageProposalId ||
            location?.search?.includes("?messageProposalId=")
            ? 4
            : activeRequestTab === 0 && activeRequestTab !== 4
            ? 0
            : activeRequestTab
        );
      }
    }, [
      buyer,
      offerIdWithContract,
      activeRequestTab,
      location?.state?.contractId,
      location?.search,
      location?.state?.messageProposalId,
    ]);

    // if saving draft-rfp for the first time
    useEffect(() => {
      if (currentRequest?.id && location.pathname.includes("draft-request"))
        navigate(`${REQUESTS}/${currentRequest?.id}`, {replace: true});
    }, [currentRequest, location.pathname, navigate]);

    return (
      <Layout>
        <div className="request __wrapper">
          {loading && <Preloader addClass="preloader500" />}
          {!loading && (
            <>
              <div className={cn("content-block_header", {"edit-mode": isEditMode})}>
                {isEditMode ? <RequestHeadEdit /> : <RequestHeadView />}
              </div>
              <div className="request content-block_wrapper">
                <Tabs
                  tabs={requestTabs}
                  active={activeRequestTab}
                  counts={[
                    null,
                    +(currentRequest?.recipients?.registered?.length ?? null) +
                      +(currentRequest?.recipients?.notRegistered?.length ?? null),
                    totalOffersCount,
                    isFinishedContract ? (
                      <IconLock width={16} height={16} />
                    ) : isContract ? (
                      <IconHourglass width={16} height={16} color={color.black} />
                    ) : null,
                    unreadMessagesCount
                      ? unreadMessagesCount
                      : totalMessagesCount ?? null,
                  ]}
                  leftContent={isDraft ? {} : {CHAT: tabLine}}
                  redCounts={[null, null, null, null, unreadMessagesCount]}
                  setActive={(id) => buyer.setActiveRequestTab(id)}
                  addClass="request__tabs"
                />
              </div>
            </>
          )}
        </div>
      </Layout>
    );
  })
);

export default RequestPage;
