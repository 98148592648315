import React from "react";
import {useTranslation} from "react-i18next";
//local
import {CustomTooltip} from "components/CustomTooltip";
import EmailRecipientsBouncedStatus from "pages/OurRequests/components/EmailRecipientsBouncedStatus";
import {IconNotificationCircle, IconPushPin} from "components/icons";
import {arrayToCommaAnd} from "helpers/helper";
import {color} from "constants/color.consts";

const ProcurementDetailsNotRegistered = ({recipient, reminderDays, description}) => {
  const {t} = useTranslation();

  const reminderDaysText = reminderDays?.length
    ? arrayToCommaAnd(reminderDays, t("AND"))
    : "";

  return (
    <div className="project-view-procurements-table_item__recipient">
      <div className="project-view-procurements-table_item__details__title">
        <span
          className={
            !!recipient.email && recipient.isBounced ? "black __strikethrough" : "black"
          }
        >
          {recipient.email ?? recipient}
        </span>
        {!!recipient.email && recipient.isBounced && <EmailRecipientsBouncedStatus />}
      </div>
      <div className="project-view-procurements-table_item__details__icon">
        {reminderDays ? (
          <CustomTooltip
            text={`${t(
              "RECIPIENTS_NOT_REGISTERED_REMINDER_TOOLTIP_01"
            )} ${reminderDaysText} ${t("RECIPIENTS_NOT_REGISTERED_REMINDER_TOOLTIP_02")}`}
            noTranslate
            centerText
          >
            <div style={{height: "20px"}}>
              <IconNotificationCircle color={color.discreteText} />
            </div>
          </CustomTooltip>
        ) : (
          <IconPushPin color={color.discreteText} />
        )}
      </div>
      <div className="project-view-procurements-table_item__details__email-attention">
        <span>{t(description)}</span>
      </div>
    </div>
  );
};

export default ProcurementDetailsNotRegistered;
