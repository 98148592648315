import React, {useState, useEffect} from "react";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
//local
import SellerSideProposalView from "../components/RequestView/SellerSideProposalView";
import SellerSideProposalEdit from "../components/RequestEdit/SellerSideProposalEdit";
import {showSuccess} from "helpers/notifications.helper";

const SellerSideProposal = inject("store")(
  observer(({store: {auth, buyer, seller, clientStore}}) => {
    const {currentOffer, currentRequest} = buyer;
    const {user} = auth;
    const {language} = clientStore;
    const {t} = useTranslation();
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const [addedFiles, setAddedFiles] = useState(currentOffer?.OfferFiles);

    const onSubmit = (values) => {
      setLoading(true);
      const documents = currentOffer.OfferFiles?.filter((item) => !!item.File).map(
        (item, i) => item.File.id
      );
      buyer
        .saveOffer({...values, documents})
        .then(() => {
          showSuccess(t("OFFER_UPDATED"));
          setEditMode(false);
        })
        .finally(() => setLoading(false));
    };

    const handleAddFile = async (data) => {
      const fileData = await seller.uploadSellerFile(data);
      setAddedFiles((prevState) => [...prevState, {File: fileData}]);
    };

    const handleRemoveFile = (id) =>
      setAddedFiles((prevState) =>
        id ? prevState.filter((item) => item?.File.id !== id) : []
      );

    useEffect(() => {
      !!addedFiles &&
        buyer.updateOffer({
          ...currentOffer,
          OfferFiles: addedFiles,
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addedFiles]);

    return (
      <div className="proposal-card seller-side">
        {editMode ? (
          <SellerSideProposalEdit
            offer={currentOffer}
            request={currentRequest}
            loading={loading}
            onSave={onSubmit}
            onCancel={() => setEditMode(false)}
            onAddFile={handleAddFile}
            onRemoveFile={handleRemoveFile}
          />
        ) : (
          <SellerSideProposalView
            offer={currentOffer}
            request={currentRequest}
            language={language}
            currentUser={user}
            onEdit={() => setEditMode(true)}
          />
        )}
      </div>
    );
  })
);

export default SellerSideProposal;
