import React, {useState} from "react";
//local
import {Layout} from "components/UI/layout";
import Organizations from "./components/Organizations";
import FindOrganizationsFilter from "components/Filters/FindOrganizationsFilter";
import {IconSearch} from "components/icons";

const FindSuppliers = () => {
  const [externalSorting, setExternalSorting] = useState(null);

  return (
    <Layout>
      <div className="find-suppliers">
        <div className="find-suppliers__search-select">
          <FindOrganizationsFilter
            title="FIND_COMPANIES_SEARCH"
            titleIcon={<IconSearch />}
            initiallyShowFilters
            needFavorites
            customParams={{}}
            externalSorting={externalSorting}
            creditReportNewsLabel
          />
        </div>
        <Organizations
          onChangeSort={(name, value) => setExternalSorting({name, value})}
        />
      </div>
    </Layout>
  );
};

export default FindSuppliers;
