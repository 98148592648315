// ======================================
// Working with data on the buyer side (REQUEST OFFERS). Data
// ======================================

import {makeAutoObservable} from "mobx";
import i18next from "i18next";
//local
import AuthStore from "./auth.store";
import {emptyRequest} from "../constants/buyer.consts";
import axiosInstance from "../axios";
import {directionReplacement, getUserDropdownList} from "helpers/helper";
import {rfpStatuses, allOffices, noOffice} from "constants/buyer.consts";
import {isEqual} from "lodash";
import OrganizationsStore from "./organizations.store";
import {showError} from "../helpers/notifications.helper";
import {
  Request,
  Recipients,
  Proposals,
  Messages,
  Contract,
} from "../pages/OurRequests/Sections";
import {
  defaultNameFieldSearch,
  defaultCreatedFieldSearch,
  defaultUpdatedFieldSearch,
} from "constants/user.consts";
import {hasPermission} from "helpers/helper";

class BuyerStore {
  // STATE. Observable and computed part
  // ****** COMMON ******
  btnLoading = false; // loading status for buttons
  loading = true; // loading status for parts of the page

  // ****** REQUESTS ******
  requests = []; // RFP's
  currentRequest = {}; // object of the current (viewed) request
  isNew = false; // status for the newly created RFP
  isEdit = false; // status for edited RFP
  isDraftSaved = true; // status for saved rfp draft
  isFilledRequiredRFPFields = false; // status based on checking the required data when creating / editing a request
  isAddLinkModal = false; // status of open / closed add link (in RFP) modal window
  isAddDaluxLinkModal = false; // status of open / closed add dalux link (in RFP) modal window
  activeRequestTab = 0; // number of the active tab when viewing a specific request
  newPublishedRFPRecipients = {}; // an object with the data of old and new recipients for the PublishedModal window
  // array of tabs when viewing a specific request
  get requestTabs() {
    return [
      {
        title: "REQUEST",
        content: Request,
        id: 0,
        hash: "#",
        visible: true,
        disabled: false,
      },
      {
        title: "RECIPIENTS",
        content: Recipients,
        id: 1,
        hash: "#",
        visible: true,
        disabled: false,
      },
      {
        title: "OFFERS",
        content: Proposals,
        id: 2,
        hash: "#",
        visible: true,
        disabled: this.hideOffersAndMessages,
      },
      {
        title: "CONTRACT",
        content: Contract,
        id: 3,
        hash: "#",
        visible: true,
        disabled: !(
          this.isContract ||
          this.activeRequestTab === 3 ||
          this.isInitialContract
        ),
      },
      {
        title: "CHAT",
        content: Messages,
        id: 4,
        hash: "#",
        visible: true,
        disabled: this.hideOffersAndMessages,
        class: "messages-tab",
      },
    ];
  }
  // status, whether the request was published
  get isPublishedRequest() {
    return this.currentRequest.status === rfpStatuses.PUBLISHED;
  }
  // hides the offer and message tabs in the create/edit mode
  get hideOffersAndMessages() {
    return this.isEdit || this.isNew;
  }
  // filter parameters for RFP list
  requestFilterParams = defaultUpdatedFieldSearch;
  // filter parameters for find recipients list in RFP
  rfpRecipientsFilterParams = defaultNameFieldSearch;

  // ****** PROPOSALS (OFFERS) ******
  isViewSpecificOffer = false; // status for switching between viewing modes for all offers and one specific offer
  unreadOffers = 0; // count of ureaded offers for all RFP's (for notifications)
  currentOffer = null; // object of the current (viewed) proposal (offer on buyer side)
  // proposal of which contract are created
  get proposalWithContract() {
    return [
      ...(this.currentRequest.recipients?.registered ?? []),
      ...(this.currentRequest.recipients?.public ?? []),
    ]?.filter((item) => item.Offer?.Contract?.id)[0];
  }
  // offer from proposal of which contract are created
  get offerWithContract() {
    return this.proposalWithContract?.Offer;
  }
  get offerIdWithContract() {
    return this.offerWithContract?.id;
  }
  // filter parameters for Proposals list (in specific RFP)
  offerFilterParams = defaultCreatedFieldSearch;

  // ****** CONTRACTS ******
  contracts = []; // Contracts on buyer side
  isInitialContract = false;
  currentContract = null; // / object of the current (viewed) contract
  unsignedBuyerContracts = 0; // count of unsigned contracts on buyer side (for notifications)
  // status showing whether the RFP has a contract
  get isContract() {
    return !!this.offerIdWithContract || !!this.currentContract?.id;
  }
  get contractId() {
    return (
      // this.offerWithContract?.Contract?.id ??
      this.currentOffer?.Contract?.id ?? this.currentContract?.id
    );
  }
  // returns the status of the buyer's signature in the current contract
  get contractOwnPersons() {
    return this.currentContract?.BuyerSignatories;
  }
  // status for a fully signed own side contract
  get isFinishedOwnSignatures() {
    return (
      !!this.contractOwnPersons?.length &&
      this.contractOwnPersons?.every((user) => !!user.BuyerSignId)
    );
  }
  // returns the status of the seller's signature in the current contract
  get contractSellerPersons() {
    return this.currentContract?.SellerSignatories.filter((user) => !!user.SellerSignId);
  }
  // status for a fully signed seller side contract
  get isFinishedOtherSignatures() {
    return (
      !!this.currentContract?.SellerSignatories?.length &&
      this.currentContract?.SellerSignatories?.every((user) => !!user.SellerSignId)
    );
  }
  // status for a fully signed (finished) contract
  get isFinishedContract() {
    return this.currentContract?.isSigned;
  }

  // filter parameters for Contracts list
  contractFilterParams = defaultCreatedFieldSearch;

  // ****** PROJECTS ******
  projects = null; // projects
  currentProject = null; // object of the current (viewed) project
  // activeProjectsTab = hasPermission(["ENABLE_PROJECTS_MINE_DEFAULT_TAB"]) ? 0 : 1; // the number for active tab on the Our projects page
  activeProjectsTab = AuthStore.hasUserPermissions
    ? hasPermission(["ENABLE_PROJECTS_MINE_DEFAULT_TAB"])
      ? 0
      : 1
    : undefined; // the number for active tab on the Our projects page
  activeProcurementsTab = 0; // the number of the active tab for procurements table on the Project page
  expandedProcurementsList = []; // ids of the expanded procurements in the specific project
  currentProcurementId = null; // id of the current (clicked) procurement (rfp)

  // list of filtered projects for a specific selected tab on the Project page
  get filterCurrentTabProjects() {
    return OrganizationsStore.currentDepartmentId === allOffices
      ? this.projects
      : this.projects?.filter((project) =>
          project.DepartmentId
            ? project.DepartmentId === OrganizationsStore.currentDepartmentId
            : OrganizationsStore.currentDepartmentId === noOffice
        );
  }
  // calculates the need to display the "New Project" button
  get isNeedNewProjectButton() {
    return this.activeProjectsTab === 2 ? false : !!this.projects?.length;
  }

  constructor() {
    makeAutoObservable(this);
  }

  // ACTIONS
  // ****** WORKING WITH REQUESTS (RFP) ******

  // getting all requests
  async getRequests(page) {
    this.loading = true;
    // it is necessary to separate RFPs that are already under contract
    await this.getContracts();
    const rfpWithContract = this.contracts
      .filter((item) => item.BuyerSignId && item.SellerSignId)
      .map((val) => val.Offer.Proposal.Request.id);
    // substituting the required sort value instead of the default value
    const direction = directionReplacement(this.requestFilterParams);
    const params = {...this.requestFilterParams, direction, page};
    try {
      const {data} = await axiosInstance.get("rfp/buyer/search", {params});
      this.loading = false;
      this.requests = data.filter((item) => !rfpWithContract.includes(item.id));
      this.currentRequest = {}; // clearing the currentRequest object to correct the logic of the RequestPage component
    } catch (e) {
      this.loading = false;
      throw e;
    }
  }

  // setting filters for an RFP request
  setRequestFilterParams(values) {
    this.requestFilterParams = values;
  }

  // setting filters for the recipients list in modal window in the RFP request
  setRfpRecipientsFilterParams(values) {
    this.rfpRecipientsFilterParams = values;
  }

  // setting EDIT status for specific RFP
  setEditStatus(status) {
    this.isEdit = status;
  }

  // setting DRAFT SAVED status for specific RFP
  setDraftSavedStatus(status) {
    this.isDraftSaved = status;
  }

  // setting NEW status for specific RFP
  setNewRFPStatus(status) {
    this.isNew = status;
  }

  // setting number for active tab of open RFP
  setActiveRequestTab(value) {
    if (this.requestTabs[value].visible) this.activeRequestTab = value;
  }

  // switching between an open and closed add link (in RFP) modal window
  setAddLinkModalStatus(status) {
    this.isAddLinkModal = status;
  }

  // switching between an open and closed add dalux link (in RFP) modal window
  setAddDaluxLinkModalStatus(status) {
    this.isAddDaluxLinkModal = status;
  }

  // setting the status based on checking the required data when creating / editing a request
  validateCurrentRequest(status) {
    this.isFilledRequiredRFPFields = status;
  }

  async duplicateRFPDocuments(id) {
    try {
      const {data} = await axiosInstance.post(`rfp/buyer/${id}/duplicate-documents`);
      return data.documents;
    } catch (e) {
      throw new Error(e);
    }
  }

  convertRecipient(recipient) {
    return {
      ...recipient,
      id: recipient.Organization.id,
      name: recipient.Organization.name,
      membersCount: recipient.Organization.membersCount ?? null,
      unreadMessageCount: recipient.MessagesStat?.unreadMessageCount ?? 0,
      totalMessageCount: recipient.MessagesStat?.totalMessageCount ?? 0,
      recentUpdatedMessageAt: recipient.MessagesStat?.recentUpdatedMessageAt ?? null,
      offered: !!recipient.Offer,
    };
  }

  convertRecievedRFP(data) {
    const registeredRecipients = data.rfp.recipients.registered.length
      ? data.rfp.recipients.registered.map((item) => this.convertRecipient(item))
      : data.rfp.recipients.registered;
    const publicRecipients = data.rfp.recipients.public.length
      ? data.rfp.recipients.public.map((item) => this.convertRecipient(item))
      : data.rfp.recipients.public;
    const haveRealRecipients = !!(
      data.rfp.recipients.registered.length + data.rfp.recipients.public.length
    );
    this.currentRequest = {
      ...data.rfp,
      recipients: haveRealRecipients
        ? {
            ...data.rfp.recipients,
            registered: registeredRecipients,
            public: publicRecipients,
          }
        : data.rfp.recipients ?? emptyRequest.recipients,
    };
  }

  // getting one specific RFP (request)
  async getCurrentRequestInfo(id, copyId, notLoading) {
    this.loading = !notLoading;
    try {
      if (!id) throw new Error();
      if (id === "draft-request") {
        this.setNewRFPStatus(true);
        // if an RFP is created by copying another RFP
        // then the next "draft-request" request does not overwrite the already filled data
        if (!this.currentRequest.ContactPersonId && !this.currentRequest.name)
          this.currentRequest = JSON.parse(JSON.stringify(emptyRequest));
      } else if (id === "copy-request") {
        this.setNewRFPStatus(true);
        const {data} = await axiosInstance.get(`rfp/buyer/${copyId}`, {copyId});
        const baseRfp = data.rfp;
        const correctDate =
          new Date(baseRfp.closedAt) - new Date() > 0 ? baseRfp.closedAt : null;
        const rfp = JSON.parse(
          JSON.stringify({
            ...emptyRequest,
            description: baseRfp.description,
            ContactPersonId: baseRfp.ContactPersonId,
            closedAt: correctDate,
            smartFormQuestions: baseRfp.smartFormQuestions ?? [],
            isOffersHidden: baseRfp.isOffersHidden,
            offersHidden: baseRfp.offersHidden,
            hardDateCloseOffers: baseRfp.hardDateCloseOffers,
            reminders: baseRfp.reminders,
          })
        );

        this.currentRequest = rfp;
        return rfp;
      } else {
        const {data} = await axiosInstance.get(`rfp/buyer/${id}`, {id});
        this.convertRecievedRFP(data);
        return {...this.currentRequest};
      }
    } catch (e) {
      showError(i18next.t("NO_RFP_ID_ERROR"));
      throw new Error(e);
    } finally {
      this.loading = false;
    }
  }

  // setting updated information on RFP after editing in store
  updateCurrentRequestInfo(request, needSaveRequest = true) {
    const isChangeRequest = isEqual(
      {...this.currentRequest, ...request},
      {...this.currentRequest}
    );
    this.currentRequest = {...this.currentRequest, ...request};
    // saving information on DRAFT-RFP after editing (creating)
    needSaveRequest &&
      (!this.currentRequest.id || this.currentRequest.status === rfpStatuses.DRAFT) &&
      !isChangeRequest &&
      this.saveCurrentRequest(this.currentRequest.id, true);
  }

  // saving updated (new) information on RFP after editing (creating)
  async saveCurrentRequest(id, notLoading, needSaveRequest) {
    if (!(this.isEdit || this.isNew) && !needSaveRequest) return;
    this.loading = !notLoading;
    // converting recipients (registered and notRegistered)
    const payloadRequest = {...this.currentRequest};
    if (payloadRequest.recipients.registered.length)
      payloadRequest.recipients = {
        ...payloadRequest.recipients,
        registered: payloadRequest.recipients.registered.map((item) => ({
          SellerId: item.id,
          MembersToReceive: item.MembersToReceive ?? [],
        })),
      };
    if (payloadRequest.recipients.notRegistered.length)
      payloadRequest.recipients = {
        ...payloadRequest.recipients,
        notRegistered: payloadRequest.recipients.notRegistered.map((item) => item.email),
      };
    // RFP to edit
    try {
      const {data} = await axiosInstance.post(
        id ? `rfp/buyer/${id}` : "rfp/create",
        payloadRequest
      );
      if (data.rfp.status === rfpStatuses.PUBLISHED)
        await this.getCurrentRequestInfo(data.rfp.id, true);
      if (data.rfp.status === rfpStatuses.DRAFT) this.convertRecievedRFP(data);
      this.isDraftSaved = true;
      return data;
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }

  // setting an object with the data of old and new recipients for the PublishedModal window
  setNewPublishedRFPRecipients(newRecipients) {
    this.newPublishedRFPRecipients = newRecipients;
  }

  // getting list of companies for find by company name dropdown
  // into Recipients tab for creating/editing RFP
  async getCompaniesForRecipients(name) {
    try {
      const {data} = await axiosInstance.get(
        `organization/search?name=${name}&size=10000`
      );
      return data.organizations.slice().sort((a, b) => (a.name > b.name ? 1 : -1));
    } catch (e) {
      throw new Error(e);
    }
  }

  // getting list of companies for find by type and regions dropdowns
  // into Recipients tab for creating/editing RFP
  async getCompaniesForFilteredRecipients(page = 1) {
    // substituting the required sort value instead of the default value
    const direction = directionReplacement(this.rfpRecipientsFilterParams);

    const params = {
      ...this.rfpRecipientsFilterParams,
      direction,
      size: 100,
      page,
    };
    try {
      const {data} = await axiosInstance.get("/organization/search", {params});
      return data;
    } catch (e) {
      throw e;
    }
  }

  // setting registered recipients specific RFP on creating / editing in store
  setRegisteredRecipients(value, method) {
    const currentRecipients = this.currentRequest.recipients.registered;
    if (method === "add")
      !currentRecipients.some((item) => item.id === value.id) &&
        this.currentRequest.recipients.registered.push(value);
    else if (method === "update")
      this.currentRequest.recipients.registered = currentRecipients.map((item) =>
        item.id === value.id ? value : item
      );
    else if (method === "remove")
      this.currentRequest.recipients.registered = currentRecipients.filter(
        (item) => item.id !== value
      );
    this.currentRequest.status === rfpStatuses.DRAFT &&
      this.saveCurrentRequest(this.currentRequest.id, true);
  }

  // setting unregistered recipients (emails) specific RFP on creating / editing in store
  setEmailRecipients(value, method) {
    if (method === "add") this.currentRequest.recipients.notRegistered.push(value);
    else if (method === "remove")
      this.currentRequest.recipients.notRegistered =
        this.currentRequest.recipients.notRegistered.filter(
          (item) => item.email !== (value.email ?? value)
        );
    this.currentRequest.status === rfpStatuses.DRAFT &&
      this.saveCurrentRequest(this.currentRequest.id, true);
  }

  // checks the presence of an email in the database
  async checkEmailRecepient(email) {
    const {data} = await axiosInstance.post("rfp/check-email", {email});
    return data;
  }

  // changing status of the RFP (DRAFT, PUBLISHED, REMOVED)
  async changeRequestStatus(id, status, notLoading) {
    try {
      await axiosInstance.post(`rfp/buyer/${id}/status`, {status});
      this.currentRequest.status = status;
    } catch (e) {
      throw e;
    }
  }

  // sending email notifications to additional suppliers after publish RFP
  sendRfptoNewSuppliers(id, params) {
    return axiosInstance.post(`rfp/buyer/${id}/send-to-suppliers`, params);
  }

  // deleting specific RFP
  async deleteRequest(id) {
    this.loading = true;
    try {
      return await axiosInstance.delete(`rfp/buyer/${id}`);
    } catch (e) {
      throw new Error(e);
    }
  }

  // add adjustment title into RFP
  async addRfpAdjustmentTitle(id, adjustmentTitles) {
    try {
      const {data} = await axiosInstance.put(`rfp/buyer/${id}/adjustment-titles`, {
        adjustmentTitles,
      });
      this.currentRequest.adjustmentTitles = [...data.rfp.adjustmentTitles];
    } catch (e) {
      throw e;
    }
  }

  // add check title into RFP
  async addRfpCheckTitle(id, checkTitles) {
    try {
      const {data} = await axiosInstance.put(`rfp/buyer/${id}/check-titles`, {
        checkTitles,
      });
      this.currentRequest.checkTitles = [...data.rfp.checkTitles];
    } catch (e) {
      throw e;
    }
  }

  // ****** WORKING WITH DOCUMENT REVISIONS IN RFP ******

  // getting revisions in RFP
  async getRevisions(id) {
    try {
      const {data} = await axiosInstance.get(`rfp/buyer/revision/${id}`);
      return data;
    } catch (e) {
      throw new Error(e);
    }
  }

  // getting specific revision in RFP
  async getRevision(rfpId, id) {
    try {
      const {data} = await axiosInstance.get(`rfp/buyer/${rfpId}/revisions/${id}`);
      return data;
    } catch (e) {
      throw new Error(e);
    }
  }

  // creating specific revision in RFP
  async createRevision(rfpId, values) {
    try {
      const {data} = await axiosInstance.post(`rfp/buyer/${rfpId}/revisions`, values);
      return data;
    } catch (e) {
      throw new Error(e);
    }
  }

  // updating specific revision in RFP
  async updateRevision(rfpId, id, values) {
    try {
      const {data} = await axiosInstance.put(
        `rfp/buyer/${rfpId}/revisions/${id}`,
        values
      );
      return data;
    } catch (e) {
      throw new Error(e);
    }
  }

  // deleting specific revision in RFP
  async deleteRevision(rfpId, id) {
    try {
      return await axiosInstance.delete(`rfp/buyer/${rfpId}/revisions/${id}`);
    } catch (e) {
      throw new Error(e);
    }
  }

  // ****** WORKING WITH PROPOSALS (OFFERS) ******

  // updating specific proposal (it status) from RFT (buyer side)
  async updateProposal(proposalId, values) {
    try {
      const {data} = await axiosInstance.put(`rfp/buyer/proposal/${proposalId}`, values);
      return data;
    } catch (e) {
      throw e;
    }
  }

  // getting list of offers for specific RFP
  async getCombinedOffers(id) {
    // substituting the required sort value instead of the default value
    const direction = directionReplacement(this.offerFilterParams);
    const params = {...this.offerFilterParams, direction};

    const questions = this.currentRequest?.smartFormQuestions?.filter(
      (item) => item.type !== "heading"
    );

    return axiosInstance
      .get(`rfp/buyer/${id}/combined-offers`, {params})
      .then(({data}) => {
        return {
          ...data,
          buyerOffers: data.buyerOffers.map((item) => ({
            ...item,
            name: item.Proposal.Organization.name,
            offerValue: item.price,
            smartFormAnswers:
              item.smartFormAnswers && !!questions.length
                ? item.smartFormAnswers.map((item) => ({
                    ...item,
                    type: questions.find((question) => question.id === item.questionId)
                      ?.type,
                  }))
                : item.smartFormAnswers,
          })),
        };
      });
  }

  //TODO !!! temporarily not in use
  // getting list of offers for specific RFP
  async getOffers(id) {
    // substituting the required sort value instead of the default value
    const direction = directionReplacement(this.offerFilterParams);
    const params = {...this.offerFilterParams, direction};

    const questions = this.currentRequest?.smartFormQuestions?.filter(
      (item) => item.type !== "heading"
    );

    return axiosInstance.get(`rfp/buyer/${id}/offers`, {params}).then(({data}) => {
      return data.map((item) => ({
        ...item,
        name: item.Proposal.Organization.name,
        offerValue: item.price,
        smartFormAnswers:
          item.smartFormAnswers && !!questions.length
            ? item.smartFormAnswers.map((item) => ({
                ...item,
                type: questions.find((question) => question.id === item.questionId)?.type,
              }))
            : item.smartFormAnswers,
      }));
    });
  }

  // getting list of offers for specific RFP
  async getManualOffers(id) {
    try {
      const {data} = await axiosInstance.get(`rfp/buyer/${id}/manual-offers`);
      return data;
    } catch (e) {
      throw new Error(e);
    }
  }

  // getting specific offer
  async getOffer(id, notLoading) {
    if (!id) {
      this.currentOffer = null;
      return;
    }
    try {
      this.loading = !notLoading;
      const {data} = await axiosInstance.get(`rfp/buyer/offer/${id}`);
      this.currentOffer = data;
      this.loading = false;
      return data;
    } catch (e) {
      throw new Error(e);
    }
  }

  // setting updated information on specific offer in RFP after editing in store
  updateOffer(offer) {
    this.currentOffer = offer;
  }

  // updating specific offer
  saveOffer(values, id) {
    const offerId = id ?? this.currentOffer.id;
    return axiosInstance.put(`rfp/buyer/offer/${offerId}`, values).then(({data}) => {
      this.updateOffer(data);
      return data;
    });
  }

  // getting specific manual offer (proposal)
  async getManualOffer(requestId, id) {
    try {
      const {data} = await axiosInstance.get(
        `rfp/buyer/${requestId}/manual-offers/${id}`
      );
      return data;
    } catch (e) {
      throw new Error(e);
    }
  }

  // creating specific manual offer (proposal)
  async createManualOffer(requestId, proposal) {
    try {
      const {data} = await axiosInstance.post(
        `rfp/buyer/${requestId}/manual-offers`,
        proposal
      );
      return data;
    } catch (e) {
      throw new Error(e);
    }
  }

  // updating specific manual offer (proposal) in RFP
  async updateManualOffer(requestId, proposalId, proposal) {
    try {
      const {data} = await axiosInstance.put(
        `rfp/buyer/${requestId}/manual-offers/${proposalId}`,
        proposal
      );
      return data;
    } catch (e) {
      throw new Error(e);
    }
  }

  // deleting specific manual offer (proposal) in RFP
  async deleteManualOffer(requestId, proposalId) {
    try {
      const {data} = await axiosInstance.delete(
        `rfp/buyer/${requestId}/manual-offers/${proposalId}`
      );
      return data;
    } catch (e) {
      throw new Error(e);
    }
  }

  // getting a unique UID for uploaded file in manual offer
  async getManualOfferFile(file) {
    try {
      const {data} = await axiosInstance.post(
        `rfp/buyer/requestId/manual-offers/upload`,
        file
      );
      return data;
    } catch (e) {
      throw new Error(e);
    }
  }

  // switching between viewing modes for all offers and one specific offer
  setViewSpecificOfferStatus(status) {
    this.isViewSpecificOffer = status;
  }

  // changing evaluate status ("reaction status") for specific proposal
  async changeEvaluateStatus(id, payload) {
    try {
      const {data} = await axiosInstance.post(`rfp/buyer/offer/${id}/status`, payload);
      this.currentOffer = data;
    } catch (e) {
      throw e;
    }
  }

  // getting a unique UID for uploaded file in contract / request
  async getUID(file) {
    try {
      const {data} = await axiosInstance.post(`rfp/buyer/upload`, file);
      return data;
    } catch (e) {
      throw new Error(e);
    }
  }

  // setting filters for an offers in RFP
  setOfferFilterParams(values) {
    this.offerFilterParams = values;
  }

  // download the offers xlsx file
  async downloadXLSX(id) {
    const {data} = await axiosInstance.get(`rfp/buyer/${id}/offers-xlsx`, {
      responseType: "blob",
    });
    return data;
  }

  // add adjustment value into offer
  async addOfferAdjustmentValue(id, adjustmentValues) {
    try {
      const {data} = await axiosInstance.put(`rfp/buyer/offer/${id}/adjustments`, {
        adjustmentValues,
      });
      return data.adjustmentValues;
    } catch (e) {
      throw e;
    }
  }

  // add check value into offer
  async addOfferCheckValue(id, checkValues) {
    try {
      const {data} = await axiosInstance.put(`rfp/buyer/offer/${id}/checks`, {
        checkValues,
      });
      return data.checkValues;
    } catch (e) {
      throw e;
    }
  }

  // add check value into offer
  async addManualOfferAdjustmentValue(requestId, proposalId, adjustmentValues) {
    try {
      const {data} = await axiosInstance.put(
        `rfp/buyer/${requestId}/manual-offers/${proposalId}/adjustments`,
        {
          adjustmentValues,
        }
      );
      return data.adjustmentValues;
    } catch (e) {
      throw e;
    }
  }

  // add check value into offer
  async addManualOfferCheckValue(requestId, proposalId, checkValues) {
    try {
      const {data} = await axiosInstance.put(
        `rfp/buyer/${requestId}/manual-offers/${proposalId}/checks`,
        {
          checkValues,
        }
      );
      return data.checkValues;
    } catch (e) {
      throw e;
    }
  }

  // ****** WORKING WITH CONTRACTS ******

  // getting list of contracts (finished)
  async getContracts(page) {
    this.loading = true;
    // substituting the required sort value instead of the default value
    const direction = directionReplacement(this.contractFilterParams);
    const params = {...this.contractFilterParams, direction, page};
    try {
      const {data} = await axiosInstance.get("rfp/buyer/contract/search", {params});
      this.loading = false;
      this.contracts = data;
    } catch (e) {
      this.loading = false;
      throw e;
    }
  }

  // getting one specific contract
  async getContract(id) {
    if (!id) {
      this.currentContract = null;
      return;
    }
    try {
      const {data} = await axiosInstance.get(`rfp/buyer/contract/${id}`);
      this.currentContract = data;
    } catch (e) {
      throw e;
    }
  }

  // creating contract
  async createContract(contractInfo) {
    try {
      const {data} = await axiosInstance.post(`rfp/buyer/contract`, contractInfo);
      await this.getContract(data.id);
      await this.getOffer(contractInfo.OfferId);
    } catch (e) {
      throw e;
    }
  }

  // removing specific contract
  async removeContract(contractId) {
    try {
      await axiosInstance.delete(`rfp/buyer/contract/${contractId}`);
      this.currentContract = null;
    } catch (e) {
      throw e;
    }
  }

  // setting the status if there is a created contract in the RFP
  setInitialContract(status) {
    this.isInitialContract = status;
  }

  // setting filters for an offers in RFP
  setContractFilterParams(values) {
    this.contractFilterParams = values;
  }

  // adding one signatory into the specific contract
  async addSignatoryOnContract(contractId, UserId) {
    try {
      await axiosInstance.post(`rfp/buyer/contract/${contractId}/signatory`, {UserId});
      await this.getContract(contractId);
    } catch (e) {
      throw e;
    }
  }

  // remove one signatory into the specific contract
  async removeSignatoryOnContract(contractId, UserId) {
    try {
      await axiosInstance.delete(`rfp/buyer/contract/${contractId}/signatory/${UserId}`);
      await this.getContract(contractId);
    } catch (e) {
      throw e;
    }
  }

  // getting contract pdf-file with watermarks
  async downloadPdfWatermarkContract(id, contractUrl) {
    try {
      const {data} = await axiosInstance.post(
        `/rfp/buyer/contract/${id}/pdf-footer`,
        {contractUrl},
        {responseType: "blob"}
      );
      return data;
    } catch (e) {
      throw e;
    }
  }

  // ****** WORKING WITH PROJECTS AND PROCUREMENTS ******

  // getting list of projects for projects modal window and dropdown in RFP
  async getProjects(notLoading, tab) {
    this.loading = !notLoading;
    const params = {tab};
    try {
      const {data} = await axiosInstance.get("projects", {params});
      this.setProjects(data);
    } catch (e) {
      throw new Error(e);
    }
    this.loading = false;
  }

  // getting list of projects (minified info) for dropdown in organization settings
  getSimplifiedProjects() {
    return axiosInstance.get("projects-simplified");
  }

  setProjects(data) {
    this.projects = data;
  }

  // getting specific project
  async getProject(id, notLoading) {
    this.loading = !notLoading;
    try {
      const {data} = await axiosInstance.get(`projects/${id}`);
      this.loading = false;
      this.currentRequest = {}; // clearing the currentRequest object to correct the logic of the RequestPage component
      return {
        ...data,
        Members: getUserDropdownList(data.Members),
      };
    } catch (e) {
      this.loading = false;
      throw new Error(e);
    }
  }

  // creating project
  async createProject(project) {
    try {
      const {data} = await axiosInstance.post("projects", project);
      return data;
    } catch (e) {
      throw new Error(e);
    }
  }

  // updating specific project
  async updateProject(project) {
    try {
      return await axiosInstance.post(`projects/${project.id}`, project);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  // getting list of members from specific project
  async getProjectMembers(projectId, permission) {
    const params = permission ? {permission} : {};
    try {
      const {data} = await axiosInstance.get(`projects/${projectId}/members`, {params});
      return data;
    } catch (e) {
      throw new Error(e);
    }
  }

  // getting procurements for the specific project
  async getProcurements(id, requestParams = {}) {
    const params = {...requestParams};
    try {
      const {data} = await axiosInstance.get(`projects/${id}/procurements`, {
        params,
      });
      return data;
    } catch (e) {
      throw new Error(e);
    }
  }

  // creating procurement for the specific project
  async createProcurement(id, procurement) {
    try {
      const {data} = await axiosInstance.put(`projects/${id}/procurements`, procurement);
      return data;
    } catch (e) {
      throw new Error(e);
    }
  }

  // getting specific procurement for the specific project
  async getProcurement(id, procurementId) {
    try {
      const {data} = await axiosInstance.get(
        `projects/${id}/procurements/${procurementId}`
      );
      return data;
    } catch (e) {
      throw new Error(e);
    }
  }

  // update specific procurement for the specific project
  async updateProcurement(id, procurementId, procurement) {
    try {
      const {data} = await axiosInstance.post(
        `projects/${id}/procurements/${procurementId}`,
        procurement
      );
      return data;
    } catch (e) {
      throw new Error(e);
    }
  }

  // delete specific procurement for the specific project
  async deleteProcurement(id, procurementId) {
    try {
      const {data} = await axiosInstance.delete(
        `projects/${id}/procurements/${procurementId}`
      );
      return data;
    } catch (e) {
      throw new Error(e);
    }
  }

  async createProcurementsList(id, procurements) {
    try {
      const {data} = await axiosInstance.post(
        `projects/${id}/procurements/create-batch`,
        {
          procurements,
        }
      );
      return data;
    } catch (e) {
      throw new Error(e);
    }
  }

  // getting procurements for the specific project
  async getProcurementStatuses() {
    try {
      const {data} = await axiosInstance.get("projects/procurements/statuses");
      return data;
    } catch (e) {
      throw new Error(e);
    }
  }

  // setting number for active tab on the Our projects page
  setActiveProjectsTab(value) {
    this.activeProjectsTab = value;
  }

  // setting number for active procerements table tab into the Project page
  setActiveProcurementsTab(value) {
    this.activeProcurementsTab = value;
  }

  // getting list of projects for create RFP modal window
  async getCreateRFPProjects() {
    try {
      const {data} = await axiosInstance.get("projects-have-rfp");
      return data;
    } catch (e) {
      throw new Error(e);
    }
  }

  // getting list of dalux projects for dropdown in project modal window
  async getDaluxProjects() {
    try {
      const {data} = await axiosInstance.get("dalux/projects");
      return data.items;
    } catch (e) {
      throw new Error(e);
    }
  }

  // getting specific dalux project for dalux link modal window in RFP
  async getDaluxProject(id) {
    try {
      const {data} = await axiosInstance.get(`dalux/projects/${id}`);
      return data.data;
    } catch (e) {
      throw new Error(e);
    }
  }

  // getting file areas for specific dalux project for dalux link modal window in RFP
  async getDaluxProjectFileAreas(id) {
    try {
      const {data} = await axiosInstance.get(`dalux/projects/${id}/file-areas`);
      return data.items;
    } catch (e) {
      throw new Error(e);
    }
  }

  // getting specific file area for specific dalux project for dalux link modal window in RFP
  async getDaluxProjectFileArea(id, fileAreaId) {
    try {
      const {data} = await axiosInstance.get(
        `dalux/projects/${id}/file-areas/${fileAreaId}`
      );
      return data.data;
    } catch (e) {
      throw new Error(e);
    }
  }

  // getting list of files for specific file area for specific dalux project for dalux link modal window in RFP
  async getDaluxProjectFileAreaFiles(id, fileAreaId) {
    try {
      const {data} = await axiosInstance.get(
        `dalux/projects/${id}/file-areas/${fileAreaId}/files`
      );
      return data.items;
    } catch (e) {
      throw new Error(e);
    }
  }

  // getting list of folders for specific file area for specific dalux project for dalux link modal window in RFP
  async getDaluxProjectFileAreaFolders(id, fileAreaId) {
    try {
      const {data} = await axiosInstance.get(
        `dalux/projects/${id}/file-areas/${fileAreaId}/folders`
      );
      return data.items;
    } catch (e) {
      throw new Error(e);
    }
  }

  // getting folder for specific file area for specific dalux project for dalux link modal window in RFP
  async getDaluxProjectFileAreaFolderInfo(id, fileAreaId, folderId) {
    try {
      const {data} = await axiosInstance.get(
        `dalux/projects/${id}/file-areas/${fileAreaId}/folders/${folderId}`
      );
      return data;
    } catch (e) {
      throw new Error(e);
    }
  }

  // getting file info for specific file area for specific dalux project for dalux link modal window in RFP
  async getDaluxProjectFileAreaFileInfo(id, fileAreaId, fileId) {
    try {
      const {data} = await axiosInstance.get(
        `dalux/projects/${id}/file-areas/${fileAreaId}/files/${fileId}`
      );
      return data;
    } catch (e) {
      throw new Error(e);
    }
  }

  // getting file for specific file area for specific dalux project for dalux link modal window in RFP
  async getDaluxProjectFileAreaFile(id, fileAreaId, fileId, revisionId) {
    try {
      const {data} = await axiosInstance.get(
        `dalux/projects/${id}/file-areas/${fileAreaId}/files/${fileId}/content${
          revisionId ? `?revisionId=${revisionId}` : ""
        }`,
        {responseType: "blob"}
      );
      return data;
    } catch (e) {
      throw new Error(e);
    }
  }

  setExpandedProcurementsList(list) {
    this.expandedProcurementsList = list;
  }

  setCurrentProcurementId(id) {
    this.currentProcurementId = id;
  }

  // getting file for specific file area for specific dalux project for dalux link modal window in RFP
  async getProcurementsAssignees(projectId) {
    try {
      const {data} = await axiosInstance.get(
        `projects/${projectId}/procurements-assignees`
      );
      return data;
    } catch (e) {
      throw new Error(e);
    }
  }

  // ****** WORKING WITH NOTIFICATIONS ******

  // getting number of unread proposals (offers on buyer side) for all requests
  async getUnreadOffers() {
    try {
      const {data} = await axiosInstance.get("rfp/buyer/search");
      this.unreadOffers = data
        .flatMap((item) => item.recipients?.registered)
        .filter((item) => item.Offer?.readed === false).length;
    } catch (e) {
      throw e;
    }
  }

  // getting number unsigned contracts on buyer side
  async getUnsignedBuyerContracts() {
    try {
      // const {data} = await axiosInstance.get("rfp/buyer/get-unsigned-buyer-contracts");

      // this.unsignedBuyerContracts = data;

      this.unsignedBuyerContracts = 0; // disable counter
    } catch (e) {
      throw e;
    }
  }

  // ****** WORKING WITH MESSAGES IN RFP ******

  // getting all messages for specific organization in specific RFP
  async getRfpOrganizationMessages({rfpId, organizationId}) {
    try {
      const {data} = await axiosInstance.get(
        `rfp/buyer/${rfpId}/${organizationId}/message/search`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  // getting all messages for ALL organization in specific RFP
  async getRfpAllMessages(rfpId) {
    try {
      const {data} = await axiosInstance.get(`rfp/buyer/${rfpId}/messages/search`);
      return data.messages;
    } catch (error) {
      throw error;
    }
  }

  // creating message for specific organization in specific RFP
  async createRfpMessage({rfpId, organizationId, message, fileId}) {
    try {
      const {data} = await axiosInstance.post(
        `rfp/buyer/${rfpId}/${organizationId ? `${organizationId}/` : ""}message`,
        {message, fileId}
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  // updating message for specific organization in specific RFP
  async updateRfpMessage({id, rfpId, organizationId, message, fileId}) {
    try {
      const {data} = await axiosInstance.put(
        `rfp/buyer/${rfpId}/${organizationId}/message/${id}`,
        {message, fileId}
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  // deleting message for specific organization in specific RFP
  async deleteRfpMessage({id, rfpId, organizationId}) {
    try {
      const {data} = await axiosInstance.delete(
        `rfp/buyer/${rfpId}/${organizationId}/message/${id}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  // uploading file for message
  messageUploadFile({rfpId, file}) {
    const data = new FormData();
    data.append("file", file.file.originFileObj);
    return axiosInstance
      .post(`rfp/buyer/${rfpId}/message/upload`, data)
      .then(({data}) => {
        return data;
      });
  }

  // creating message for specific organization in specific RFP
  async remindRfpRecipient(rfpId, organizationId) {
    try {
      const {data} = await axiosInstance.post(
        `rfp/buyer/${rfpId}/${organizationId}/remind`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  // ****** BULK OPERATIONS ******

  // bulk operations with procurements into specific project
  async procurementsBulkUpdate(projectId, values) {
    try {
      const {data} = await axiosInstance.post(
        `projects/${projectId}/procurements/bulk-update-data`,
        values
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  // ****** OTHER COMMON METHODS ******

  // getting a list of agreements (e.g.: for create RFP)
  async getAgreements() {
    try {
      return await axiosInstance.get("rfp/standard-agreements");
    } catch (e) {
      throw new Error(e);
    }
  }

  // getting an invite link for unregistered recipient in RFP
  async getInviteLink(id, email) {
    try {
      const {data} = await axiosInstance.get(
        `rfp/buyer/${id}/get-invite-link/?${new URLSearchParams({email})}`
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

const buyer = new BuyerStore();
export default buyer;
