import React from "react";
import {useTranslation} from "react-i18next";
// local
import {obfuscatePersonalNumber} from "helpers/helper";

export const ContractSignatures = ({
  ownPersons,
  otherPersons,
  contractId,
  isSellerSide,
}) => {
  const {t} = useTranslation();

  const ContractSignature = ({person, index, isOwn}) => (
    <div className="df-column" key={`${index}-${person?.id}-${isOwn ? "own" : "other"}`}>
      <span className="contract-tab_done-signatures__title">
        {`${t("SIGNATURE_BY")} ${person?.name}, ${obfuscatePersonalNumber(
          person?.personalNumber
        )}`}
      </span>
      <span className="contract-tab_done-signatures__value">{person?.id}</span>
    </div>
  );

  return (
    <div className="contract-tab_done-signatures mt-4 pt-8">
      {!isSellerSide &&
        !!ownPersons &&
        ownPersons.map((person, i) => (
          <ContractSignature person={person} index={i} isOwn />
        ))}
      {!!otherPersons &&
        otherPersons.map((person, i) => <ContractSignature person={person} index={i} />)}

      {isSellerSide &&
        !!ownPersons &&
        ownPersons.map((person, i) => (
          <ContractSignature person={person} index={i} isOwn />
        ))}

      <span className="contract-tab_done-signatures__title">{t("CONTRACT_ID")}</span>
      <span className="contract-tab_done-signatures__value">{contractId}</span>
    </div>
  );
};
