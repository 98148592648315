import React from "react";

export const IconLinkHmb = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="2" fill="#572481" />

      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 15.5C7.38137 15.5 4.91523 14.9454 3.05614 13.9385C1.1425 12.9015 0.0886383 11.5031 0.0886383 10.0002C0.0886383 8.49712 1.1425 7.09852 3.05614 6.06177C4.91523 5.05458 7.38137 4.5 10 4.5C12.6186 4.5 15.0848 5.05458 16.9439 6.06177C18.8573 7.09852 19.9114 8.49712 19.9114 10.0002C19.9114 11.5031 18.8573 12.9015 16.9439 13.9385C15.0848 14.9454 12.6184 15.5 10 15.5Z"
        fill="#572481"
      />
      <path
        d="M10.0123 5.02389C15.2061 5.02389 19.4164 7.25162 19.4164 10.0002C19.4164 12.7482 15.2061 14.9761 10.0123 14.9761C4.8184 14.9761 0.607947 12.7482 0.607947 10.0002C0.607947 7.25162 4.8184 5.02389 10.0123 5.02389ZM10.0123 4.70947C7.45067 4.70947 5.03772 5.23976 3.21772 6.20295C1.33386 7.19983 0.296356 8.54824 0.296356 10.0002C0.296356 11.4518 1.33386 12.8002 3.21772 13.7968C5.03795 14.76 7.4509 15.2906 10.0123 15.2906C12.5736 15.2906 14.9866 14.7603 16.8068 13.7968C18.6907 12.8 19.7282 11.4518 19.7282 10.0002C19.7282 8.54847 18.6907 7.19983 16.8068 6.20295C14.9866 5.23999 12.5736 4.70947 10.0123 4.70947Z"
        fill="#FED300"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.91454 8.91008V11.3745C9.02249 11.5877 9.22863 11.4731 9.35613 11.5927C9.43068 11.7465 9.43795 11.9087 9.32818 12.0946H7.62863C7.51931 11.9369 7.56613 11.7666 7.58545 11.6005C7.72886 11.4584 7.93159 11.5847 8.14568 11.4259C8.25363 10.5032 8.1609 9.52379 8.18636 8.6124C7.99227 8.39377 7.79749 8.48223 7.65545 8.42975C7.55818 8.36031 7.56386 8.27025 7.56499 8.18431C7.56613 8.09769 7.57454 8.01152 7.58204 7.89075H9.85499L10.5452 10.2899L10.6345 10.2922L11.3427 7.87677H13.5307C13.6504 8.04704 13.5725 8.21731 13.5873 8.36535C13.4248 8.51133 13.217 8.3885 13.0389 8.56083V11.4295C13.2184 11.5638 13.3868 11.5013 13.5339 11.5505C13.6393 11.7167 13.5945 11.8874 13.5673 12.0884H11.36C11.2679 11.9167 11.2873 11.7513 11.3343 11.5925C11.4502 11.4788 11.6214 11.5751 11.77 11.3929V8.88442L11.7336 8.86448L10.8057 12.0799C10.4929 12.1466 10.2191 12.1205 9.88363 12.1033L8.98045 8.88121L8.91454 8.91008Z"
        fill="#FED300"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.83341 8.54576C2.65864 8.39474 2.46227 8.49672 2.30227 8.40253C2.18773 8.24601 2.25295 8.07803 2.26591 7.88416H4.51136C4.60614 8.04343 4.57295 8.21095 4.54227 8.37136C4.425 8.50634 4.21955 8.39061 4.08705 8.59755C4.01409 8.88172 4.06045 9.22295 4.06364 9.59351H5.40545C5.505 9.24976 5.47091 8.89638 5.42386 8.57693C5.29659 8.39497 5.09318 8.50451 4.98114 8.37503C4.92432 8.22309 4.94432 8.06955 4.97955 7.88645H7.21182C7.31955 8.03655 7.27386 8.20613 7.24977 8.37113C7.09773 8.52468 6.86273 8.37343 6.66659 8.58793C6.6125 9.4952 6.63477 10.4616 6.65614 11.3842C6.83932 11.5955 7.06227 11.4773 7.22159 11.5616C7.29909 11.7465 7.27227 11.9104 7.23273 12.0944H5.01591C4.92318 11.9214 4.95045 11.7674 4.97432 11.6168C5.07659 11.4569 5.28386 11.5866 5.41727 11.4211C5.49932 11.0625 5.46023 10.6662 5.43705 10.2246C4.99205 10.144 4.55636 10.1673 4.08409 10.2088C4.02614 10.6245 4.05932 11.0169 4.065 11.3361C4.19114 11.5847 4.39182 11.4779 4.51955 11.5758C4.60273 11.7385 4.55864 11.9044 4.53773 12.0953H2.31591C2.20477 11.9113 2.25205 11.7468 2.27295 11.596C2.44409 11.4699 2.64477 11.5854 2.83386 11.4191C2.83341 10.4811 2.83341 9.50001 2.83341 8.54576Z"
        fill="#FED300"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.7277 10.1368C17.5623 10.0459 17.385 9.97711 17.158 9.8733C17.55 9.69203 17.8727 9.49197 17.9534 9.06755C18.0789 8.40709 17.6711 7.91942 16.8514 7.87863C15.8902 7.83074 14.9248 7.86763 13.967 7.86763C13.8768 8.06013 13.8909 8.22513 13.9409 8.39013C14.0895 8.50815 14.2948 8.38807 14.4714 8.56476V11.4151C14.2891 11.5902 14.0836 11.463 13.9377 11.5886C13.9009 11.7557 13.8732 11.9218 13.983 12.121C14.9509 12.1056 15.9318 12.1606 16.912 12.0843C17.1505 12.0657 17.3916 12.0355 17.6168 11.931C17.965 11.7697 18.142 11.4979 18.1691 11.117C18.1982 10.7084 18.1152 10.3495 17.7277 10.1368ZM15.7164 8.48317C16.053 8.43917 16.3882 8.36401 16.6398 8.64199C16.8482 8.8723 16.8243 9.15005 16.6457 9.43238C16.3534 9.65788 16.053 9.64849 15.7161 9.59234L15.7164 8.48317ZM16.858 11.1798C16.6089 11.653 16.1598 11.5114 15.7077 11.5125V10.2755C16.1495 10.1245 16.6214 10.1886 16.7989 10.4396C16.9611 10.6685 16.9845 10.9389 16.858 11.1798Z"
        fill="#FED300"
      />
    </svg>
  );
};
