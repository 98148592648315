import React from "react";

export const IconLinkBjerking = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="2" fill="#f4f4f4" />

      <path
        d="M6.83721 8.55072H6.46512V10.1739H6.83721V8.55072ZM16.8837 8.55072H16.5116V11.4493H16.8837V8.55072ZM3.11628 11.4493H3.48837V8.55072H3.11628V11.4493ZM13.5349 7.27536V6H12.0465V7.27536H11.3023V6H9.81395V7.27536H5.72093V6H4.23256V7.27536H3.48837V6H2V12.7246H4.23256V14H5.72093V12.7246H9.06977V10.1739H9.81395V12.7246H15.3953V14H18V7.27536H13.5349ZM4.23256 12.3768H2.37209V6.34783H3.11628V7.62319H4.23256V12.3768ZM5.34884 13.6522H4.60465V7.62319H5.34884V13.6522ZM5.34884 7.27536H4.60465V6.34783H5.34884V7.27536ZM7.5814 11.1014H6.46512V11.4493H7.5814V12.3768H5.72093V7.62319H7.5814V11.1014ZM9.81395 9.82609H9.06977V8.55072H8.69767V12.3768H7.95349V7.62319H9.81395V9.82609ZM12.0465 9.82609H10.9302V10.1739H12.0465V12.3768H11.3023V11.1014H10.9302V12.3768H10.186V6.34783H10.9302V8.89855H11.3023V7.62319H12.0465V9.82609ZM13.1628 12.3768H12.4186V7.62319H13.1628V12.3768ZM13.1628 7.27536H12.4186V6.34783H13.1628V7.27536ZM15.3953 12.3768H14.6512V8.55072H14.2791V12.3768H13.5349V7.62319H15.3953V12.3768ZM17.6279 13.6522H15.7674V12.7246H16.8837V12.3768H15.7674V7.62319H17.6279V13.6522Z"
        fill="black"
      />
    </svg>
  );
};
