import React, {useEffect, useState, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
// local
import {CustomTooltip} from "components/CustomTooltip";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {QualificationAnswersModal} from "components/modals/QualificationAnswersModal";
import CommentModal from "components/modals/CommentModal";
import HistoryInfo from "components/HistoryInfo";
import {
  IconSkullEmpty,
  IconFavoriteWaiting,
  IconFavoriteDone,
  IconList,
  IconHourglass,
} from "components/icons";
import {qualificationStatus} from "constants/user.consts";
import {ConfirmModal} from "components/ConfirmModal";
import {showWarning} from "helpers/notifications.helper";
import {historyBlacklistTableColumns} from "constants/table.consts";
import {color} from "constants/color.consts";

const supplierQualificationStatusTooltip = {
  [qualificationStatus.WAITING]: "SUPPLIER_QUALIFICATION_WAITING_QUALIFICATION",
  [qualificationStatus.SENT]: "SUPPLIER_QUALIFICATION_SENT_QUALIFICATION",
  [qualificationStatus.CONFIRMED]: "SUPPLIER_QUALIFICATION_CONFIRMED_QUALIFICATION",
};

const Favorite = inject("store")(
  observer(({store: {auth, organization, clientStore}}) => {
    const {organizationDetail, favoritesOrganization} = organization;
    const {user, isStaff} = auth;
    const {t} = useTranslation();

    const [qualificationData, setQualificationData] = useState(null);
    const [showCommentModal, setCommentModal] = useState(false);

    const isIncludedInFavorite = favoritesOrganization?.some(
      (x) => x.id === organizationDetail.id
    );

    const isIncludesInBlackList = organization?.blacklistOrganization?.some(
      (x) => x.id === organizationDetail.id
    );

    const hasSupplierQualification = useMemo(
      () => !!organizationDetail.SupplierQualifications?.length,
      [organizationDetail.SupplierQualifications]
    );
    const qualification = hasSupplierQualification
      ? organizationDetail.SupplierQualifications[0]
      : null;
    const qualificationConfirmedStatus = useMemo(
      () => qualification?.status === qualificationStatus.CONFIRMED,
      [qualification]
    );
    const cantRemoveFromFavorites =
      hasSupplierQualification && !qualificationConfirmedStatus;

    const enableSuppliersQualification = useMemo(
      () => user.ActiveOrganization?.enableSuppliersQualification,
      [user.ActiveOrganization]
    );

    const handleClick = () => {
      if (isIncludesInBlackList)
        isStaff ? setCommentModal(true) : showWarning(t("BLACKLIST_PERMISSION_TEXT"));
      else if (isIncludedInFavorite)
        ConfirmModal({
          title: t("WANT_DELETE_FAVORITE_SUPPLIER"),
          text: t("CANT_DELETE_FAVORITE_SUPPLIER"),
          type: "warning",
          onOk: () => organization.removeFromFavorite(organizationDetail.id),
        });
      else if (enableSuppliersQualification)
        ConfirmModal({
          title: t("WANT_ADD_FAVORITE_SUPPLIER_IF_ENABLED_QUALIFICATION"),
          text: t("CANT_ADD_FAVORITE_SUPPLIER_IF_ENABLED_QUALIFICATION"),
          type: "warning",
          onOk: () => organization.addToFavorite(organizationDetail.id),
        });
      else organization.addToFavorite(organizationDetail.id);
    };

    const handleShowQualificationAnswersModal = () => {
      clientStore.getQualificationAnswers(qualification.id, "buyer").then(({data}) =>
        setQualificationData({
          ...data,
          questions: data.questions.map((question) => ({
            ...question,
            files: question.files?.map((file) => ({...file, ...file.File})),
          })),
          answers: data.answers
            ? data.answers.map((answer) => ({
                ...answer,
                files: answer.files?.map((file) => ({...file, ...file.File})),
              }))
            : [],
        })
      );
    };

    const handleChangeBlacklistHistoryComment = (historyId, values) => {
      organization
        .changeBlacklistHistoryComment(
          historyId,
          values,
          organizationDetail?.BlacklistHistoryEvents
        )
        .then((BlacklistHistoryEvents) =>
          organization.setOrganizationDetail({BlacklistHistoryEvents})
        );
    };

    useEffect(() => {
      organization.getFavorites();
    }, [organization, organization.organizationDetail]);

    return (
      <div className="presentation-page-header__favorite">
        <button
          onClick={handleClick}
          disabled={cantRemoveFromFavorites}
          style={{position: "relative"}}
        >
          {isIncludesInBlackList ? (
            <IconSkullEmpty />
          ) : isIncludedInFavorite ||
            (hasSupplierQualification && enableSuppliersQualification) ? (
            enableSuppliersQualification ? (
              <CustomTooltip
                text={
                  hasSupplierQualification
                    ? supplierQualificationStatusTooltip[qualification.status]
                    : "SUPPLIER_QUALIFICATION_NOT_PASSED_QUALIFICATION"
                }
              >
                <div style={{height: "20px", display: "inline"}}>
                  <IconFavoriteDone
                    color={qualificationConfirmedStatus ? color.green : color.gold}
                  />
                </div>
              </CustomTooltip>
            ) : (
              <IconFavoriteDone />
            )
          ) : (
            <span className="add-to-favorites">
              <IconFavoriteWaiting addClass="mr-2" />
              {t("OUR_FAVOURITES_LINK")}
            </span>
          )}
          {isIncludesInBlackList && (
            <HistoryInfo
              data={organizationDetail?.BlacklistHistoryEvents}
              columns={historyBlacklistTableColumns}
              onChangeComment={handleChangeBlacklistHistoryComment}
            />
          )}
          {isIncludesInBlackList && showCommentModal && (
            <CommentModal
              onConfirm={(comment) =>
                organization.addToBlackList(organizationDetail.id, {comment})
              }
              onClose={() => setCommentModal(false)}
              placeholder={`${t("BLACKLIST_COMMENT_PLACEHOLDER_REMOVE")}\n\n${t(
                "BLACKLIST_COMMENT_PLACEHOLDER_COMMON"
              )}`}
            />
          )}
        </button>
        {!isIncludesInBlackList && !!qualification && enableSuppliersQualification && (
          <React.Fragment>
            {qualification.status === qualificationStatus.WAITING && (
              <IconHourglass color={color.black} />
            )}
            {[qualificationStatus.SENT, qualificationStatus.CONFIRMED].includes(
              qualification.status
            ) && (
              <ButtonTransparent
                icon={<IconList color={color.green} />}
                noBorder
                small
                onClick={handleShowQualificationAnswersModal}
                addClass="icon-button pa-0 ml-2"
              />
            )}
          </React.Fragment>
        )}

        {qualificationData && (
          <QualificationAnswersModal
            initialQualification={qualificationData}
            buyerSide
            readOnly
            onClose={({needUpdateAnswers}) => setQualificationData(null)}
          />
        )}
      </div>
    );
  })
);

export default Favorite;
