import React, {memo} from "react";
import {useTranslation} from "react-i18next";
//local
import {IconFunnel, IconCross} from "components/icons";
import {Input} from "components/Input";
import {ButtonTransparent} from "../buttons/ButtonTransparent";
import CustomRadioSwitch from "components/CustomRadioSwitch";
import {VideoInstructionLink} from "components/VideoInstructionLink";
import HandyPointerBlob from "components/HandyPointerBlob";
import {color} from "constants/color.consts";
import {InfoTooltip} from "components/InfoTooltip";

export const DecorativeSearch = memo(
  ({
    name,
    resetEvent,
    icon,
    changeCheck,
    isFilterVisible,
    defaultChecked,
    searchFocus,
    searchOnChange,
    searchPlaceholder,
    hideEntireFilter,
    hideFilterParts,
    showSearch,
    videoLinkPrefix,
    creditReportNewsLabel,
  }) => {
    const {t} = useTranslation();
    return (
      <div className="decorative-search">
        {icon}
        <h3 className="custom-title __uppercase">{t(name)}</h3>
        {videoLinkPrefix && (
          <VideoInstructionLink
            label={`VIDEO_LINK_${videoLinkPrefix}_LABEL`}
            link={`VIDEO_LINK_${videoLinkPrefix}_LINK`}
            addClass="ml-8 mr-4"
          />
        )}
        {showSearch && !hideEntireFilter && (
          <div className="find-suppliers__inp-name">
            <Input
              focus={searchFocus}
              small
              onChange={searchOnChange}
              placeholder={searchPlaceholder}
            />

            {creditReportNewsLabel && (
              <span className="external-report-explainer">
                <HandyPointerBlob
                  showPointer
                  noAnimation
                  pointerBeforeBadge
                  pointerDirection="left"
                  addClass="ml-1"
                  text="NEW_FEATURE_CREDIT_REPORT_COMPANIES"
                />
                <InfoTooltip
                  tooltip="NEW_FEATURE_CREDIT_REPORT_COMPANIES_TOOLTIP"
                  addClass="ml-2"
                />
              </span>
            )}
          </div>
        )}
        {resetEvent && !hideEntireFilter && hideFilterParts ? (
          <div className="decorative-search__buttons">
            <div className="df-row-center mb-2">
              <CustomRadioSwitch
                defaultChecked={defaultChecked}
                addClass="mr-2"
                isActive={changeCheck}
              />
              <IconFunnel />
            </div>
            {isFilterVisible && (
              <ButtonTransparent
                name={t("CLEAR_FILTERS_BUTTON")}
                onClick={resetEvent}
                icon={<IconCross color={color.link} width="7" height="7" />}
                addClass="clear-filters __uppercase"
                tiny
              />
            )}
          </div>
        ) : null}
      </div>
    );
  }
);
