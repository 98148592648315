import React, {useState} from "react";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import {v4 as uuidv4} from "uuid";
//local
import {dateTimeFormat} from "helpers/datetime";
import ProposalItemBudget from "./ProposalItemBudget";
import {formattedNumber} from "helpers/number.formats";
import {getFullName} from "helpers/helper";
import {Input} from "components/Input";
import {adjustmentSymbolsAndColors} from "constants/buyer.consts";
import {TextBadge} from "components/TextBadge";
import {color} from "constants/color.consts";
import {CustomTooltip} from "components/CustomTooltip";
import {MedalsRating} from "components/MedalsRating";
import {IconLock, IconBullHorn, IconHandOnRight} from "components/icons/index";
import {offerToMedalRating} from "constants/buyer.consts";
import FinancialSnapshotSelect from "components/selects/FinancialSnapshotSelect";
import {hasPermission} from "helpers/helper";
import {LockValue} from "components/LockValue";
import {REQUESTS} from "constants/routes.const";
import cn from "classnames";

const ProposalItem = inject("store")(
  observer(
    ({
      store: {buyer},
      proposal,
      isHidedInfo,
      offersClosedAt,
      isShowAdjustments,
      isShowAnswers,
      isShowChecks,
      adjustmentsIds,
      clickable,
      questions,
      checkIds,
      isPublic,
      language,
      budgetValue,
      onMouseEnter,
      onMouseLeave,
      onChangeNote,
      onChangeAdjustment,
      onChangeCheck,
      onChangeRating,
      isHovered,
      isEditingTitleMode,
      publicPageMode,
    }) => {
      const {t} = useTranslation();
      const {requestId} = useParams();
      const [currentAdjustmentValues, setCurrentAdjustmentValues] = useState(
        proposal.adjustmentValues ?? []
      );
      const [currentCheckValue, setCurrentCheckValue] = useState(null);
      const [currentBuyerNote, setCurrentBuyerNote] = useState(proposal.buyerNote);

      const rejectUser = getFullName(
        proposal?.RejectMessageUser?.firstName,
        proposal?.RejectMessageUser?.lastName
      );
      const rejectDateTime = dateTimeFormat(proposal.rejectMessageUpdatedAt);
      const rejectMessageHead =
        rejectUser && rejectDateTime ? `${rejectUser} | ${rejectDateTime}\n\n` : "";

      const answers = proposal.smartFormAnswers;
      const checkValues = proposal.checkValues ?? [];
      const isRead = proposal.readed;
      const isPriceAdjustments = !!currentAdjustmentValues?.length;
      const adjustmentValue = isPriceAdjustments
        ? currentAdjustmentValues.reduce((sum, item) => sum + item.value, 0)
        : 0;

      const offerCompanyNumber = proposal.Proposal?.Organization?.Company?.companyNumber;
      const snapshots =
        proposal.Proposal?.Organization?.Company?.CompanyCreditSafePdfReports;

      const canSetRanking = hasPermission(["ENABLE_RFT_OFFERS_OFFER_RANKING"]);
      const canEditAdjustments = hasPermission(["CREATE_RFT_OFFERS_ADJUSTMENTS"]);
      const canEditChecks = hasPermission(["CREATE_RFT_OFFERS_CHECKS"]);
      const canEditNotes = hasPermission(["EDIT_RFT_OFFERS_NOTES"]);
      const canViewProposal = hasPermission(["READ_RFT_OFFERS_OFFER"]);

      // allows or blocks the display of the value depending on isHidedInfo
      const showValue = (value, hideIcon) =>
        isHidedInfo ? (
          <IconLock
            width="14"
            height="14"
            addClass="proposals-tab_body__row__icon-lock"
            color={color[hideIcon ? "white" : "discreteText"]}
          />
        ) : (
          value
        );

      const choice = (value) => (!value ? "equally" : value > 0 ? "more" : "less");

      const getNewValues = (values, value, titleId, key) => {
        const hasValue = values.some((item) => item[key] === titleId);
        const newValue = {
          id: uuidv4(),
          [key]: titleId,
          value: value,
        };
        const newValues = hasValue
          ? values.map((item) => (item[key] === titleId ? newValue : item))
          : [...values, newValue];
        return newValues;
      };

      const handleClickOnProposal = () => {
        buyer.getOffer(proposal.id);
        buyer.setViewSpecificOfferStatus(true);
        window.history.replaceState(
          null,
          null,
          `${REQUESTS}/${requestId}/offers/${proposal.id}`
        );
      };

      return (
        <LockValue
          canPermissions={["READ_RFT_OFFERS_OFFER"]}
          needLabel
          needShowModalToSubscribe
          needRightIcon={false}
        >
          <div
            className={cn("proposals-tab_body", {__clickable: clickable})}
            onClick={() => clickable && canViewProposal && handleClickOnProposal()}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <div className={`proposals-tab_body__row __name${isRead ? "" : " __unread"}`}>
              <div className="df-row-center full-width hght-32">
                {isPublic ? (
                  <IconBullHorn size="16" addClass="mr-2" />
                ) : (
                  <IconHandOnRight height={16} width={15} addClass="mr-2" />
                )}
                {proposal.name}
                <LockValue
                  addClassIcon="ml-0"
                  canPermissions={["ENABLE_RFT_OFFERS_OFFER_RANKING"]}
                  needLabel
                  needShowModalToSubscribe
                  noLockIcon
                  dimmedWhenLocked
                >
                  <MedalsRating
                    place={offerToMedalRating[proposal.medal]}
                    shortView={!isHovered}
                    onClick={(e, place) => {
                      if (canSetRanking) {
                        e.stopPropagation();
                        onChangeRating(
                          isPublic ? "public" : "common",
                          place,
                          proposal.id
                        );
                      } else e.preventDefault();
                    }}
                    addClass="mla pl-2"
                  />
                </LockValue>
              </div>
              {!publicPageMode && (
                <FinancialSnapshotSelect
                  companyNumber={offerCompanyNumber}
                  initialSnapshots={snapshots}
                  addClass="hght-32"
                />
              )}
            </div>
            <ProposalItemBudget
              adjustmentValues={proposal.adjustmentValues}
              price={proposal.offerValue}
              budgetValue={budgetValue}
              language={language}
              isHidedInfo={isHidedInfo}
              offersClosedAt={offersClosedAt}
            />
            <div className="proposals-tab_body__row __no-divider">
              {!isHidedInfo && (
                <React.Fragment>
                  {proposal.evaluate ? (
                    <TextBadge small text={"EVALUATING"} color="green" />
                  ) : proposal.evaluate === null ? (
                    <div style={{marginTop: "19px"}}></div>
                  ) : (
                    <TextBadge
                      tooltip={`${rejectMessageHead}"${proposal.rejectMessage}"`}
                      noTooltipTranslate
                      small
                      text={"DECLINED"}
                      centerTooltipText
                      color="red"
                    />
                  )}
                </React.Fragment>
              )}
            </div>
            <div className="proposals-tab_body__row mt-2 __no-divider __bold">
              {!isHidedInfo && adjustmentSymbolsAndColors[choice(adjustmentValue)].symbol}{" "}
              {showValue(adjustmentValue?.toLocaleString(language ?? "sv"), true)}
            </div>
            {isShowAdjustments &&
              !!adjustmentsIds?.length &&
              adjustmentsIds.map((id, i) => {
                const value = currentAdjustmentValues.find(
                  (adj) => adj.adjustmentTitleId === id
                )?.value;
                return isHovered && !isEditingTitleMode ? (
                  <div
                    key={`${id}_${proposal.id}`}
                    onClick={(e) => e.stopPropagation()}
                    className="proposals-tab_body__row __no-divider"
                  >
                    <LockValue
                      addClassIcon="ml-0"
                      canPermissions={["CREATE_RFT_OFFERS_ADJUSTMENTS"]}
                      needLabel
                      needShowModalToSubscribe
                      noLockIcon
                    >
                      <Input
                        tiny
                        flatView
                        fullWidth
                        type="number"
                        customHeight="26"
                        fontSize="12"
                        placeholder={t(
                          "RFP_PROPOSALS_LIST_ADJUSTMENTS_COLUMN_NOTE_PLACEHOLDER"
                        )}
                        className="input pl-2"
                        value={value}
                        onChange={(e) => {
                          if (canEditAdjustments) {
                            const newValues = getNewValues(
                              currentAdjustmentValues,
                              e.target.value ? +e.target.value : null,
                              id,
                              "adjustmentTitleId"
                            );

                            setCurrentAdjustmentValues(newValues);
                            onChangeAdjustment(
                              isPublic ? "public" : "common",
                              newValues,
                              proposal.id
                            );
                          }
                        }}
                      />
                    </LockValue>
                  </div>
                ) : (
                  <div
                    key={i}
                    className={`proposals-tab_body__row __inner${
                      i === adjustmentsIds?.length - 1 ? " __no-divider" : ""
                    }`}
                  >
                    {adjustmentSymbolsAndColors[choice(value)].symbol}{" "}
                    {value?.toLocaleString(language ?? "sv") ?? 0}
                  </div>
                );
              })}
            {!publicPageMode && isShowAdjustments && (
              <div className="proposals-tab_body__row __no-divider" />
            )}
            <div className="proposals-tab_body__row __question-row __no-divider mt-2" />
            {isShowAnswers && (
              <div className="proposals-tab_body-answers">
                {questions.map((item, i) =>
                  answers[i] && !isHidedInfo ? (
                    <div
                      key={answers[i].id}
                      className="proposals-tab_body__row __question-row"
                    >
                      {answers[i].type === "boolean" &&
                        `${
                          answers[i].value === "Yes"
                            ? t("YES")
                            : answers[i].value === "No"
                            ? t("NO")
                            : ""
                        }`}
                      {answers[i].type === "number" && formattedNumber(answers[i].value)}{" "}
                      {answers[i].type === "number" &&
                        answers[i].value &&
                        t(item.measurementUnit)}
                      {answers[i].type === "string" && (
                        <CustomTooltip text={answers[i].value}>
                          {answers[i].value}
                        </CustomTooltip>
                      )}
                    </div>
                  ) : (
                    <div
                      className="proposals-tab_body__row __question-row"
                      style={{height: "32px"}}
                      key={i}
                    >
                      {showValue()}
                    </div>
                  )
                )}
              </div>
            )}
            <div className="proposals-tab_body__row __no-divider mt-2" />
            {isShowChecks &&
              checkIds.map((id, i) => {
                const value = checkValues.find((adj) => adj.checkTitleId === id)?.value;
                return (
                  <div
                    key={`${id}_${proposal.id}`}
                    className={cn("proposals-tab_body__row __question-row", {
                      "__no-divider": !publicPageMode || ++i === checkIds.length,
                    })}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {publicPageMode ? (
                      value
                    ) : (
                      <LockValue
                        canPermissions={["CREATE_RFT_OFFERS_CHECKS"]}
                        needLabel
                        addClassWrapper="fg2"
                        needShowModalToSubscribe
                        noLockIcon
                      >
                        <Input
                          tiny
                          flatView
                          fullWidth
                          customHeight="26"
                          fontSize="12"
                          placeholder={t(
                            "RFP_PROPOSALS_LIST_CHECKS_COLUMN_NOTE_PLACEHOLDER"
                          )}
                          className="input pl-2"
                          value={
                            currentCheckValue?.checkTitleId === id
                              ? currentCheckValue?.value
                              : value ?? ""
                          }
                          disabled={isEditingTitleMode}
                          onChange={(e) => {
                            if (canEditChecks) {
                              setCurrentCheckValue({
                                checkTitleId: id,
                                value: e.target.value,
                              });
                              onChangeCheck(
                                isPublic ? "public" : "common",
                                [
                                  ...checkValues.filter(
                                    (check) => check.checkTitleId !== id
                                  ),
                                  {
                                    id: uuidv4(),
                                    checkTitleId: id,
                                    value: e.target.value,
                                  },
                                ],
                                proposal.id
                              );
                            }
                          }}
                          onFocus={(e) =>
                            setCurrentCheckValue({
                              checkTitleId: id,
                              value: e.target.value,
                            })
                          }
                          onFocusLeave={() => setCurrentCheckValue(null)}
                        />
                      </LockValue>
                    )}
                  </div>
                );
              })}
            {!publicPageMode && isShowChecks && (
              <div className="proposals-tab_body__row __no-divider" />
            )}
            <div
              className="proposals-tab_body__row __notes"
              onClick={(e) => e.stopPropagation()}
            >
              <LockValue
                addClassIcon="ml-0"
                canPermissions={["EDIT_RFT_OFFERS_NOTES"]}
                needLabel
                needShowModalToSubscribe
                noLockIcon
              >
                <TextareaAutosize
                  maxRows="3"
                  className="textarea"
                  name="messageText"
                  value={currentBuyerNote}
                  disabled={publicPageMode}
                  onChange={(e) => {
                    if (canEditNotes) {
                      setCurrentBuyerNote(e.target.value);
                      !publicPageMode &&
                        onChangeNote(
                          isPublic ? "public" : "common",
                          e.target.value,
                          proposal.id
                        );
                    }
                  }}
                  placeholder={t("RFP_PROPOSALS_LIST_NOTES_COLUMN_NOTE_PLACEHOLDER")}
                />
              </LockValue>
            </div>
          </div>
        </LockValue>
      );
    }
  )
);

export default ProposalItem;
