// import React from "react";
// import {inject, observer} from "mobx-react";
// // local
// import {OrganizationCard} from "./OrganizationCard";
// import OrganizationCardHead from "./OrganizationCardHead";
// import TableTitleResult from "components/Table/TableTitleResult";
// import {Preloader} from "components/Preloader";
// import cn from "classnames";

// const Organizations = inject("store")(
//   observer(
//     ({
//       store: {buyer, organization, auth},
//       filteredRecipients,
//       addedRecipients,
//       onActionClick,
//       onChangeOrder,
//       onSelectChange,
//     }) => {
//       const {user} = auth;

//       const {favoritesOrganization, blacklistOrganization} = organization;
//       const {newPublishedRFPRecipients} = buyer;
//       const isResults = !!filteredRecipients?.length;
//       const modalAddedRecipientsCount =
//         addedRecipients?.length - newPublishedRFPRecipients.notRemoveRegistered?.length;

//       return filteredRecipients ? (
//         <div className={cn("organizations", {__results: isResults})}>
//           <TableTitleResult
//             title={"RESULTS"}
//             totalItems={filteredRecipients?.length ?? 0}
//           />
//           <OrganizationCardHead
//             onChangeOrder={onChangeOrder}
//             total={filteredRecipients?.length ?? 0}
//             added={modalAddedRecipientsCount}
//             onSelectChange={onSelectChange}
//           />
//           <div className="organizations__suppliers">
//             {isResults &&
//               filteredRecipients.map((organization) => (
//                 <OrganizationCard
//                   key={organization.id}
//                   cantBeRemoved={newPublishedRFPRecipients.notRemoveRegistered
//                     ?.map((item) => item.id)
//                     .includes(organization.id)}
//                   isAlreadyInRecipients={addedRecipients
//                     ?.map((item) => item.id ?? item.SellerId)
//                     .includes(organization.id)}
//                   organization={organization}
//                   favorites={favoritesOrganization}
//                   blacklisted={blacklistOrganization}
//                   onActionClick={onActionClick}
//                   showTitleIcon
//                   hasSuppliersQualifications={
//                     !!organization.SupplierQualifications?.length
//                   }
//                   userPermissions={user.permissions}
//                   isEnableSuppliersQualification={
//                     user.ActiveOrganization?.enableSuppliersQualification
//                   }
//                 />
//               ))}
//           </div>
//         </div>
//       ) : (
//         <Preloader addClass="maa" />
//       );
//     }
//   )
// );

// export default Organizations;

import React, {useEffect, useRef, useState, useCallback, useMemo} from "react";
import {inject, observer} from "mobx-react";
// local
import {OrganizationCard} from "./OrganizationCard";
import OrganizationCardHead from "./OrganizationCardHead";
import TableTitleResult from "components/Table/TableTitleResult";
import {Preloader} from "components/Preloader";
import cn from "classnames";

const Organizations = inject("store")(
  observer(
    ({
      store: {buyer, organization, auth},
      filteredRecipients,
      addedRecipients,
      onActionClick,
      onChangeOrder,
      onSelectChange,
      loadMoreRecipients, // Новый пропс: функция для загрузки следующего чанка
    }) => {
      const {user} = auth;
      const {favoritesOrganization, blacklistOrganization} = organization;
      const {newPublishedRFPRecipients} = buyer;

      const [recipients, setRecipients] = useState(filteredRecipients);
      const [loading, setLoading] = useState(false);
      const [hasMore, setHasMore] = useState(false);
      const listRef = useRef(null);

      const isResults = useMemo(
        () => !!filteredRecipients?.totalItems,
        [filteredRecipients]
      );
      const modalAddedRecipientsCount =
        addedRecipients?.length - newPublishedRFPRecipients.notRemoveRegistered?.length;

      // const loadNextPageData = useCallback(async () => {
      //   if (!(recipients?.totalPages - 1 >= recipients?.currentPage)) return;
      //   const nextPage = ++recipients.currentPage;
      //   const nextData = await loadMoreRecipients(nextPage);
      //   setRecipients((prev) => ({
      //     ...nextData,
      //     organizations: [...prev.organizations, ...nextData.organizations],
      //   }));
      //   setHasMore(recipients.totalPages > nextPage); // Если новых данных нет, останавливаем загрузку
      //   listRef.current.addEventListener("scroll", handleScroll);
      // }, [hasMore, loading, loadMoreRecipients]);

      // const handleScroll = useCallback(() => {
      //   const scrollPosition = listRef.current.scrollTop + listRef.current.clientHeight;
      //   const maxScrollPosition = listRef.current.scrollHeight - 2000;
      //   if (listRef.current && scrollPosition >= maxScrollPosition) {
      //     listRef.current.removeEventListener("scroll", handleScroll);
      //     loadNextPageData();
      //   }
      // }, [loadNextPageData]);

      const loadNextPageData = async () => {
        if (!(recipients?.totalPages - 1 >= recipients?.currentPage)) return;
        const nextPage = ++recipients.currentPage;
        const nextData = await loadMoreRecipients(nextPage);
        setRecipients((prev) => ({
          ...nextData,
          organizations: [...prev.organizations, ...nextData.organizations],
        }));
        setHasMore(recipients.totalPages > nextPage); // Если новых данных нет, останавливаем загрузку
        listRef.current.addEventListener("scroll", handleScroll);
      };

      const handleScroll = () => {
        const scrollPosition = listRef.current.scrollTop + listRef.current.clientHeight;
        const maxScrollPosition = listRef.current.scrollHeight - 2000;
        if (listRef.current && scrollPosition >= maxScrollPosition) {
          listRef.current.removeEventListener("scroll", handleScroll);
          loadNextPageData();
        }
      };

      useEffect(() => {
        const container = listRef.current;
        if (container) {
          container.addEventListener("scroll", handleScroll);
          return () => container.removeEventListener("scroll", handleScroll);
        }
      }, [handleScroll]);

      // initialazing list on first render
      useEffect(() => {
        if (!!filteredRecipients) {
          console.log("filteredRecipients - ", filteredRecipients);
          setRecipients(filteredRecipients); // Загружаем первый чанк
          console.log("UYRTYRTYRTRYTRRT");
          setHasMore(filteredRecipients?.totalItems > 100);
          listRef?.current.scrollTo({top: 0});
        }
      }, [filteredRecipients]);

      return (
        <div className={cn("organizations", {__results: isResults})}>
          <TableTitleResult
            title={"RESULTS"}
            totalItems={filteredRecipients?.totalItems ?? 0}
          />
          <OrganizationCardHead
            onChangeOrder={onChangeOrder}
            total={filteredRecipients?.totalItems ?? 0}
            added={modalAddedRecipientsCount}
            onSelectChange={onSelectChange}
          />
          <div className="organizations__suppliers" ref={listRef}>
            {isResults &&
              recipients?.organizations.map((organization) => (
                <OrganizationCard
                  key={organization.id}
                  cantBeRemoved={newPublishedRFPRecipients.notRemoveRegistered
                    ?.map((item) => item.id)
                    .includes(organization.id)}
                  isAlreadyInRecipients={addedRecipients
                    ?.map((item) => item.id ?? item.SellerId)
                    .includes(organization.id)}
                  organization={organization}
                  favorites={favoritesOrganization}
                  blacklisted={blacklistOrganization}
                  onActionClick={onActionClick}
                  showTitleIcon
                  hasSuppliersQualifications={
                    !!organization.SupplierQualifications?.length
                  }
                  userPermissions={user.permissions}
                  isEnableSuppliersQualification={
                    user.ActiveOrganization?.enableSuppliersQualification
                  }
                />
              ))}
          </div>
        </div>
      );
    }
  )
);

export default Organizations;
